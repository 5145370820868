<template>
  <div class="view" v-if="value">
    <div class="view-item">
      <el-image
        style="width: 100%; height: 100%"
        :src="value"
        :preview-src-list="fileData"
        @click="handlePictureCardPreview"
      ></el-image>
    </div>
  </div>
</template>
<script>
// 目前只支持单张图片展示
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String || Array,
      default: "",
    },
  },
  watch: {
    value: {
      handler: function (val) {
        if (Array.isArray(val)) {
          this.fileData = val;
        } else {
          this.fileData = [val];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      show: false,
      fileData: [],
    };
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
    },
  },
};
</script>
<style lang="less" scoped>
.view {
  display: flex;
  &-item {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    cursor: pointer;
    line-height: 146px;
    vertical-align: top;
    border-radius: 5px;
  }
}
</style>
