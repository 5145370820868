<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>合作伙伴管理</el-breadcrumb-item>
      <el-breadcrumb-item>伙伴认证审核</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryInfo"
          ref="queryInfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="auditStatus">
            <div style="display: inline-block" class="divSpan">审核状态：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryInfo.auditStatus"
                :options="ResultOptions"
                :optionKey="ParentIdKeys"
                Splaceholder="请选择"
                @clear="getList"
                @change="changeType"
              ></ELselect>
            </div>
          </el-form-item>
          <el-form-item prop="company">
            <div style="display: inline-block" class="divSpan">企业名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryInfo.company"
                clearable
                @clear="getList"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="searchChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="dataList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="company"
            label="企业名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="contact"
            label="企业联系人"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="contactPhone"
            label="联系人手机号"
            min-width="120px"
          ></el-table-column>
          <el-table-column
            prop="contactId"
            label="联系人身份证"
            min-width="120px"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="申请日期"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="审核状态" min-width="100px">
            <template slot-scope="{ row }">
              <el-tag type="success" v-if="row.auditStatus == 1"
                >审核未通过</el-tag
              >
              <el-tag type="danger" v-if="row.auditStatus == 2"
                >审核未通过</el-tag
              >
              <el-tag type="warning" v-if="row.auditStatus == 3">待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="审核日期"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="操作" min-width="150px" fixed="right">
            <template slot-scope="{ row }">
              <el-button
                type="primary"
                size="mini"
                @click="Approve(row)"
                v-show="row.status == 0"
                >审核</el-button
              >
              <el-tag plain v-show="row.status > 0"> 已审核</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-size="queryInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 审核 -->
    <el-dialog
      :close-on-click-modal="false"
      title="审核"
      :visible.sync="ApprovalDialog"
      width="600px"
      @close="ApprovalDialogClosed"
      center
    >
      <el-form
        :model="form"
        ref="formRef"
        label-width="160px"
        style="padding-right: 40px"
      >
        <el-form-item label="企业名称：" prop="company">
          <el-input
            v-model.trim="form.company"
            disabled
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码：" prop="uniCode">
          <el-input
            v-model.trim="form.uniCode"
            disabled
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细通讯地址：" prop="address">
          <el-input
            v-model.trim="form.address"
            disabled
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名：" prop="contact">
          <el-input
            v-model.trim="form.contact"
            disabled
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号：" prop="contactPhone">
          <el-input
            placeholder="请输入手机号"
            disabled
            v-model.trim="form.contactPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人身份证号：" prop="contactId">
          <el-input
            v-model.trim="form.contactId"
            disabled
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照：" prop="url">
          <PicView v-model="form.url" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passApproval(1)" v-preventReClick
          >审核通过</el-button
        >
        <el-button @click="passApproval(0)">审核不通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from "@/components/myself/el_select";
import {
  GetPartnerAuditCertList,
  PostAuditPartnerRefuse,
} from "@/api/partner.js";
import PicView from "@/components/myself/UploadCommon/PicPreview";

export default {
  components: { ELselect, PicView },
  data() {
    return {
      queryInfo: {
        auditStatus: "",
        company: "",
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      dataList: [],

      ResultOptions: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 3,
          name: "待审核",
        },
        {
          id: 2,
          name: "审核已通过",
        },
        {
          id: 1,
          name: "审核未通过",
        },
      ],
      ParentIdKeys: {
        value: "id",
        label: "name",
        label2: "",
      },
      ApprovalDialog: false,
      form: {
        id: "",
        company: "",
        uniCode: "",
        address: "",
        contact: "",
        contactPhone: "",
        contactId: "",
        url: "",
      },
      refuseReason: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let data = {
        auditStatus: this.queryInfo.auditStatus,
        company: this.queryInfo.name,
      };
      const res = await GetPartnerAuditCertList(
        this.queryInfo.pageNum,
        this.queryInfo.pageSize,
        data
      );
      if (res.status !== 200) return this.$message.error("获取失败");
      if (res.data.status !== 200) return this.$message.error(res.data.message);
      this.dataList = res.data.data.list;
      this.total = res.data.data.total;
    },
    changeType(val) {
      this.queryInfo.auditStatus = val;
      this.getList();
    },
    searchChange() {
      this.queryInfo.pageNum = 1;
      this.getList();
    },
    clean() {
      this.$refs.queryInfoRef.resetFields();
      this.getList();
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    async Approve(row) {
      this.form.id = row.id;
      this.form.company = row.company;
      this.form.uniCode = row.uniCode;
      this.form.address = row.address;
      this.form.contact = row.contact;
      this.form.contactPhone = row.contactPhone;
      this.form.contactId = row.contactId;
      this.form.url =
        row.url ||
        "https://test-audit-relaper-image.oss-cn-hangzhou.aliyuncs.com/2023022403/284342524808605696/%E5%9F%8E%E5%B8%82%E7%8E%AF%E5%A2%83%E6%B1%A1%E6%9F%93%E7%9B%91%E6%8E%A7%E5%A4%A7%E5%B1%8F.png";
      this.ApprovalDialog = true;
    },

    async passApproval(flag) {
      if (flag === 1) {
        this.sendApprovalReq({ flag, note: "" });
      } else {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "拒绝原因不能为空",
          inputPattern: /.+/,
        }).then(({ value }) => {
          this.sendApprovalReq({ flag, note: value });
        });
      }
    },
    async sendApprovalReq(flag, note) {
      const data = {
        id: this.form.id,
        isPassed: flag,
        note,
      };
      const res = await PostAuditPartnerRefuse(data);
      if (res.status !== 200) return this.$message.error("请求失败");
      if (res.data.status !== 200) return this.$message.error(res.data.message);
      this.getList();
      this.ApprovalDialog = false;
    },
    ApprovalDialogClosed() {
      this.$refs.formRef.resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}
/deep/ .el-form-item__content {
  margin-right: 40px;
}
</style>
