import axios from '@/libs/api.request'

export const addPartner = (quiese) => {
  let uri = '/partner/add'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const auditPartner = (quiese) => {
  let uri = '/partner/audit'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const getPartnerAuditList = (pageNum, pageSize, quiese) => {
  let uri = '/partner/audit/list/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}


export const queryGrade = (filter) => {
  let uri = '/partner/getgrade/' + filter
  return axios.request({
    url: uri,
    method: 'get'
  })
}


export const setGrade = (quiese) => {
  let uri = '/partner/setgrade'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const setUserGrade = (userId, gradeId) => {
  let uri = '/partner/user/grade/' + userId + '/' + gradeId
  return axios.request({
    url: uri,
    method: 'post'
  })
}


export const auditcert = (id) => {
  let uri = '/partner/auditcert/detail/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}
// 获取合作伙伴实名制审核列表
export const GetPartnerAuditCertList = (pageNum, pageSize, data) => {
  return axios.request({
    url: `/partner/auditcert/list/${pageNum}/${pageSize}`,
    method: 'post',
    data
  })
}

// 获取合作伙伴实名制审核详情
export const GetPartnerAuditCertDetail = (id) => {
  return axios.request({
    url: `/partner/auditcert/detail/${id}`,
    method: 'get'
  })
}

// 审核拒绝合作伙伴实名制
export const PostAuditPartnerRefuse = (data) => {
  return axios.request({
    url: '/partner/auditcert',
    method: 'post',
    data
  })
}
